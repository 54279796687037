import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@components';

import sty from './pagination.module.scss';

function Pagination(props) {
  const { isBack, isNext, handleBack, handleNext, pageNo, totalPage } = props;
  return (
    <div className={cx(sty['page-wrapper'], 'mt-50', ' d-flex ', 'justify-content-center')}>
      <Button
        type={1}
        className={cx(sty['page-btn'], 'mr-2')}
        label="Previous"
        disableNext={isBack}
        handleClickNext={handleBack}
      />
      <div className={cx(sty.page, 'mr-2', 'mb-0', 'form-label', 'bg-white')}>
        <span>{`Page ${pageNo} of ${totalPage}`}</span>
      </div>
      <Button
        type={1}
        className="page-btn"
        label="Next"
        disableNext={isNext}
        handleClickNext={handleNext}
      />
    </div>
  );
}

Pagination.propTypes = {
  isBack: PropTypes.bool,
  isNext: PropTypes.bool,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  pageNo: PropTypes.number,
  totalPage: PropTypes.number,
};

export default Pagination;
