import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import WARNING_ICON from '../../../public/images/warning-red.svg';

function CustomDropdown(props) {
  const {
    name,
    isSearchable,
    isClearable,
    isDisabled,
    onChange,
    getOption,
    placeholder,
    className,
    pristine,
    initialValue,
    onError,
    required = true,
    light,
    customStyle = {},
  } = props;

  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!pristine && required) {
      let error = '';
      if (!value) {
        error = `Please select the ${placeholder}`;
      } else {
        error = '';
      }
      setError();
      if (onError) {
        onError({ name, value: error || false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholder, pristine, value]);

  function dropdownIndicator(props) {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.542"
          height="8.978"
          viewBox="0 0 16.542 8.978"
        >
          <path
            id="Path_642"
            dataname="Path 642"
            d="M2192.917,251.041,2185,258.959l-7.917-7.917"
            transform="translate(-2176.729 -250.688)"
            fill="none"
            stroke={light ? '#fff' : '#8ca4aa'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </svg>
      </components.DropdownIndicator>
    );
  }

  function onSelect(selectedVal) {
    if (onChange) {
      onChange(selectedVal);
    }
    setValue(selectedVal);
  }

  const selectorStyles = {
    singleValue: provided => ({
      ...provided,
      color: customStyle && customStyle.color ? `${customStyle.color} !important` : provided.color,
    }),
  };

  return (
    <>
      <Select
        styles={selectorStyles}
        classNamePrefix="pl"
        className={`g-select ${className}`}
        isDisabled={isDisabled || false}
        isSearchable={isSearchable || false}
        isClearable={isClearable || false}
        placeholder={placeholder || 'Select...'}
        components={{
          DropdownIndicator: dropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onSelect}
        value={value}
        options={getOption}
      />
      {error ? (
        <div className="errors-wrapper">
          <img src={WARNING_ICON} alt="warning-icon" />
          <p className="error">{error}</p>
        </div>
      ) : null}
    </>
  );
}

CustomDropdown.propTypes = {
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  getOption: PropTypes.array,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  initialValue: PropTypes.object,
  pristine: PropTypes.bool,
  required: PropTypes.bool,
  light: PropTypes.bool,
  onError: PropTypes.func,
  customStyle: PropTypes.any,
};

export default CustomDropdown;
