import cx from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSettingContext } from '@context/SettingContent';
import { DateCustomInput, Loader } from '@components';
import { useUserContext } from '@context/UserContent';
import { addBusinessDays, eachDayOfInterval, format } from 'date-fns';
import { extendCampaign } from '@apis/Campaign';

import sty from './extendCampaign.module.scss';

function ExtendCampaignModal(props) {
  const { campaignId, closeFn, closeTxt, onCampaignExtended, saveTxt, msg, center, className } =
    props;
  const {
    setting: { countryDateFormat },
  } = useSettingContext();
  const { user } = useUserContext();
  const [selectedEndDate, setEndDate] = useState(null);
  const [selectedExpiryDate, setExpiryDate] = useState(null);
  const [isLoading, handleLoading] = useState(false);
  const [error, setError] = useState('');

  function restrictedDates() {
    return +process.env.NEXT_PUBLIC_CAMPAIGN_MIN_START_DAYS
      ? eachDayOfInterval({
          start: new Date(),
          end: addBusinessDays(new Date(), process.env.NEXT_PUBLIC_CAMPAIGN_MIN_START_DAYS),
        })
      : {};
  }

  async function daveExtendCampaignDates() {
    if (selectedEndDate && selectedExpiryDate) {
      handleLoading(true);
      const extendedDates = {
        endDate: format(new Date(selectedEndDate), 'yyyy-MM-dd'),
        offerEndDate: format(new Date(selectedExpiryDate), 'yyyy-MM-dd'),
      };
      const res = await extendCampaign(user.accessToken, extendedDates, campaignId);
      handleLoading(false);
      if (res.status) {
        onCampaignExtended(res.data);
      } else {
        setError(res.message);
      }
    } else {
      setError('Please choose valid expiry dates');
    }
  }

  return (
    <div className={className}>
      {isLoading && <Loader />}
      <div className={sty['alert-overlay']} />
      <div className={cx(sty['alert-modal-dialog'], { [sty['center-alert']]: center })}>
        <div className={sty['alert-modal']}>
          <div className={sty['alert-modal-header']}>{msg}</div>
          <div className={cx(sty['cla-container'], 'd-flex')}>
            <div className={cx(sty['cal-input'], 'mr-2')}>
              <div className="DayPickerInput">
                <DatePicker
                  selected={selectedEndDate}
                  onChange={date => setEndDate(date)}
                  highlightDates={restrictedDates()}
                  minDate={new Date()}
                  dateFormat={countryDateFormat}
                  popperClassName="some-custom-class"
                  popperPlacement="top-end"
                  customInput={<DateCustomInput placeholderText="Ends on..." />}
                />
              </div>
            </div>
            <div className={sty['cal-input']}>
              <div className="DayPickerInput">
                <DatePicker
                  selected={selectedExpiryDate}
                  onChange={date => setExpiryDate(date)}
                  startDate={selectedEndDate}
                  highlightDates={restrictedDates()}
                  minDate={selectedEndDate ? new Date(selectedEndDate) : new Date()}
                  dateFormat={countryDateFormat}
                  popperClassName="some-custom-class"
                  popperPlacement="top-end"
                  customInput={<DateCustomInput placeholderText="Reward expires on..." />}
                />
              </div>
            </div>
          </div>

          {error && <p className={sty['error-message']}>Error: {error}</p>}

          <div className={cx('d-flex', sty['btn-ctr'])}>
            <button type="button" className={sty['btn-modal']} onClick={closeFn}>
              {closeTxt}
            </button>
            <button type="button" className={sty['btn-modal']} onClick={daveExtendCampaignDates}>
              {saveTxt}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ExtendCampaignModal.propTypes = {
  campaignId: PropTypes.string,
  closeFn: PropTypes.func,
  closeTxt: PropTypes.string,
  onCampaignExtended: PropTypes.func,
  saveTxt: PropTypes.string,
  msg: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default ExtendCampaignModal;
