import axios from 'axios';

import REQUEST from '@helpers/http.service';

export async function loginUser(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/login',
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function registerUser(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/register',
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function forgotPassword(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/forgot-password',
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function resetPassword(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/reset-password',
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function createPassword(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/account-activate',
      data: {
        ...data,
        confirmPassword: data.password,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

// get token info for reset password
export async function tokenDetail({ type, token }) {
  try {
    const res = await REQUEST({
      method: 'GET',
      url: `/get-token-details/${token}?type=${type}`,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function logoutUser() {
  const res = await axios({
    method: 'POST',
    url: '/api/logout',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      token: '',
    },
  });
  return res;
}

export async function verifyEmail(data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: '/check',
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}
