import { Doughnut } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

import { Card } from '@components';
import propTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './PieChart.module.scss';
import defaultChartColors from '../chart-colors';
// Register the components with Chart.js
ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend, Title);

const PieChart = ({
  title,
  cutoutTitle,
  labels,
  barTitle,
  chartData,
  cutoutPercentage,
  chartColors = defaultChartColors,
}) => {
  // Calculate total
  const total = chartData.reduce((acc, value) => acc + value, 0) || 0;

  // Calculate percentages and format them
  const chartDataPercentages = chartData.map(value => {
    if (value === 0) {
      return 0;
    }
    const percentage = (value / total) * 100;
    // Check if the percentage is a whole number
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);
  });

  // Update labels to include formatted percentages
  const updatedLabels = labels.map((label, index) => `${label} (${chartDataPercentages[index]}%)`);

  const chartConfigData = {
    // Placeholder data for the chart
    labels: updatedLabels,
    datasets: [
      {
        label: ` ${barTitle || ' '}`,
        data: chartData,
        backgroundColor: chartColors,
        borderRadius: 8,
        borderWidth: 2,
        borderAlign: 'inner', // Ensure the border appears inside the chart for clarity
        hoverOffset: 10, // Increases hover offset for 3D effect
        cutout: '65%', // Adjusted cutout
        shadowOffsetX: 3, // Add some shadow offset
        shadowOffsetY: 3,
        shadowBlur: 1,
        shadowColor: 'rgba(0, 0, 0, 0.1)', // Dark shadow for depth
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: false, // Use circles for legend markers
          pointStyle: 'circle', // Rounded point style
          boxWidth: 10, // Control the width of the circles
          boxHeight: 10, // Control the height of the circles (if needed)
          padding: 20, // Padding between the items
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: '#FFF',
        anchor: 'center',
        align: 'center',
        clip: true,
        padding: 3,
        font: {
          size: 12,
          weight: 'bold',
          lineHeight: 1.2,
        },
        // Formatter to hide labels for zero values
        formatter: value => {
          return value === 0 ? null : `${value}`; // Hide zero values
        },
      },
    },
    animation: {
      duration: 2000, // Set animation duration to 1000 milliseconds (1 second)
    },
  };

  return (
    <Card>
      <div className={styles.pieChart}>
        <span className={styles.chartTitle}>{title}</span>
        <div className={styles.chartContainer}>
          {total ? (
            <>
              <Doughnut data={chartConfigData} options={options} />
              <div className={styles.cutoutText}>
                <span>{cutoutPercentage || 0}%</span>
                {cutoutTitle ? <p>{cutoutTitle}</p> : null}
              </div>
            </>
          ) : (
            <span className={styles.chartNoData}>No data Available</span>
          )}
        </div>
      </div>
    </Card>
  );
};

PieChart.propTypes = {
  title: propTypes.string,
  cutoutTitle: propTypes.string,
  labels: propTypes.array,
  barTitle: propTypes.string,
  chartData: propTypes.array,
  cutoutPercentage: propTypes.any,
  chartColors: propTypes.array,
};

export default PieChart;
