import REQUEST from '@helpers/http.service';

const rootPath = '/campaign';

export async function getAllCampaigns(accessToken, status) {
  try {
    const res = await REQUEST({
      method: 'GET',
      url: status ? `${rootPath}?status=${status}` : rootPath,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function deleteCampaigns(accessToken, id) {
  try {
    const res = await REQUEST({
      method: 'DELETE',
      url: `${rootPath}/${id}`,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function cloneCampaigns(accessToken, data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/clone`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

/**
 * Name Campaign or complete Campaign Info
 */
export async function getCampaign(accessToken, id) {
  try {
    const res = await REQUEST({
      method: 'GET',
      url: `${rootPath}/${id}`,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function postCampaign(accessToken, data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function updateCampaign(accessToken, data, id) {
  try {
    const res = await REQUEST({
      method: 'PUT',
      url: `${rootPath}/update/${id}`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

// Api call to extend the campaign
export async function extendCampaign(accessToken, data, id) {
  try {
    const res = await REQUEST({
      method: 'PUT',
      url: `${rootPath}/extend/${id}`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

/**
 * Design Campaign Info
 */

export async function saveCampaignLogo(accessToken, data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/image`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function saveDesignCampaignData(accessToken, data, type) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/${type}/design`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

/**
 * Setup Campaign Info
 */

export async function saveCampaignSetupData(accessToken, data, type) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/${type}/setup`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

/**
 * Review Campaign Info
 */

export async function saveCampaignReviewData(accessToken, data, type) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/${type}/review`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function changeCampStatus(accessToken, data) {
  try {
    const res = await REQUEST({
      method: 'POST',
      url: `${rootPath}/change-status`,
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function getDashboardCampaign(accessToken, id = '', hardRefresh = false) {
  let url = '';

  if (id) {
    if (hardRefresh) {
      url = `/dashboard/${id}?hardRefresh=${hardRefresh}`;
    } else {
      url = `/dashboard/${id}`;
    }
  } else if (hardRefresh) {
    url = `/dashboard?hardRefresh=${hardRefresh}`;
  } else {
    url = `/dashboard`;
  }

  try {
    const res = await REQUEST({
      method: 'GET',
      url,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function getUserParticipationStats(accessToken, id = '', year = '') {
  let url = `/v2/analytics/user-participation`;
  if (id && year) {
    url = `${url}/${id}?year=${year}`;
  } else if (id) {
    url = `${url}/${id}`;
  } else if (year) {
    url = `${url}?year=${year}`;
  }

  try {
    const res = await REQUEST({
      method: 'GET',
      url,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}
