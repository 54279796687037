import PropTypes from 'prop-types';
import Head from 'next/head';
import cx from 'classnames';

import { CustomLink, Button } from '@components';
import URL from '@helpers/url-constant';

import sty from './invalidlink.module.scss';

function InvalidLink({ link = '', btnLabel, msg, onClick, child = false }) {
  function goToLogin() {
    window.location.replace(URL.LOGIN);
  }

  return (
    <>
      <Head>
        <title>Invalid Link | Payment Loyalty</title>
      </Head>
      <div className={cx(sty['invalid-page-wrap'], { [sty['h-100']]: !child })}>
        <div className={cx(sty['invalid-page-content'], { [sty['pt-150']]: !child })}>
          <div className={sty['warning-symbol']} />
          <h1>Invalid Link</h1>
          <p>{msg}</p>
          {link ? (
            <CustomLink btnLike color="blue-bg" href={link} text={btnLabel} />
          ) : (
            <Button type={1} handleClickNext={onClick || goToLogin} label={btnLabel} />
          )}
        </div>
      </div>
    </>
  );
}

InvalidLink.propTypes = {
  link: PropTypes.string,
  btnLabel: PropTypes.string.isRequired,
  msg: PropTypes.string,
  child: PropTypes.bool,
  onClick: PropTypes.func,
};

export default InvalidLink;
