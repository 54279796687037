import cx from 'classnames';
import PropTypes from 'prop-types';

import sty from './alert.module.scss';

function AlertModal(props) {
  const { type, header, closeFn, closeTxt, saveFn, saveTxt, msg, center, className, headerImage } =
    props;
  if (type.toString() === '1') {
    return (
      <div className={className}>
        <div className={sty['alert-overlay']} />
        <div className={cx(sty['alert-modal-dialog'], { [sty['center-alert']]: center })}>
          <div className={sty['alert-modal']}>
            <div className={sty['alert-modal-header']}>
              {header || <img src={headerImage || '/images/check.svg'} alt="icon" />}
            </div>
            <div className={sty['alert-modal-content']}>{msg}</div>
            <div className={cx('d-flex', sty['btn-ctr'])}>
              <button
                type="button"
                className={cx(sty['btn-modal'], sty['btn-fluid'])}
                onClick={closeFn}
              >
                {closeTxt}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={className}>
      <div className={sty['alert-overlay']} />
      <div className={cx(sty['alert-modal-dialog'], { [sty['center-alert']]: center })}>
        <div className={sty['alert-modal']}>
          <div className={sty['alert-modal-header']}>
            {header || <img src={headerImage || '/images/warning.svg'} alt="icon" />}
          </div>
          <div className={sty['alert-modal-content']}>{msg}</div>
          <div className={cx('d-flex', sty['btn-ctr'])}>
            <button type="button" className={sty['btn-modal']} onClick={closeFn}>
              {closeTxt}
            </button>
            <button type="button" className={sty['btn-modal']} onClick={saveFn}>
              {saveTxt}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AlertModal.propTypes = {
  type: PropTypes.string,
  header: PropTypes.string,
  closeFn: PropTypes.func,
  closeTxt: PropTypes.string,
  saveFn: PropTypes.func,
  saveTxt: PropTypes.string,
  msg: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string,
  headerImage: PropTypes.string,
};

export default AlertModal;
