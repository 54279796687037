import Link from 'next/link';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './link.module.scss';

function LinkComponent({
  href,
  children,
  text,
  className,
  color,
  size,
  decor,
  btnLike = false,
  targetType = '_self',
  customStyle = {},
}) {
  return (
    <Link href={href}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={cx(
          className,
          style.link,
          { [style['btn-like']]: btnLike },
          style[color],
          style[size],
          style[decor],
        )}
        target={targetType}
        style={customStyle}
      >
        {text || children}
      </a>
    </Link>
  );
}

LinkComponent.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  color: PropTypes.string,
  decor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  targetType: PropTypes.string,
  btnLike: PropTypes.bool,
  customStyle: PropTypes.any,
};

export default LinkComponent;
