import propTypes from 'prop-types';
import styles from './Card.module.scss';

function Card({ children }) {
  return <div className={styles.plCard}>{children}</div>;
}

Card.propTypes = {
  children: propTypes.any,
};

export default Card;
