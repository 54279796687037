/**
 *
 *  order default value: true
 * 	true stands for ascending order
 *  false stands for descending order
 */
// eslint-disable-next-line import/prefer-default-export
export const sortBy = ({ field, type, order }, array) => {
  const sortingArray = [...array];
  if (sortingArray.length) {
    sortingArray.sort((data1, data2) => {
      const item1 = order ? data1[field] : data2[field];
      const item2 = order ? data2[field] : data1[field];

      switch (type) {
        case 'string':
          if (item1.toUpperCase() < item2.toUpperCase()) {
            return -1;
          }
          if (item2.toUpperCase() > item1.toUpperCase()) {
            return 1;
          }
          return 0;
        case 'date':
          return new Date(item1) - new Date(item2);
        default:
          return item1 - item2;
      }
    });
  }
  return sortingArray;
};

/**
 *
 * local storage
 *
 */

export const loadState = (name = '') => {
  try {
    const serializedState = sessionStorage.getItem(name);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    // Sentry.captureException(err);
    return undefined;
  }
};

export const saveState = (name, data) => {
  try {
    const serializedState = JSON.stringify(data);
    sessionStorage.setItem(name, serializedState);
  } catch (err) {
    // Sentry.captureException(err);
  }
};

export const removeState = (name = '') => {
  try {
    if (!name) {
      sessionStorage.clear();
    } else {
      sessionStorage.removeItem(name);
    }
  } catch (err) {
    // Sentry.captureException(err);
  }
};

export const doubleDigit = number => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDateWithoutTime = date => {
  if (date) {
    const formattedDate = new Date(date);
    const day = doubleDigit(formattedDate.getUTCDate());
    const year = formattedDate.getUTCFullYear();
    const month = doubleDigit(formattedDate.getUTCMonth() + 1);
    const newDate = `${year}-${month}-${day}`;
    return new Date(`${newDate}T00:00:00.000Z`);
  }
  return '';
};

// Function to convert reward value as proper decimals(5.5 -> 5.50)
export const formatRewardValue = value => {
  if (!value) {
    return value;
  }
  const amountValue = Number(value);
  if (Number.isInteger(amountValue)) {
    return amountValue;
  }
  return amountValue.toFixed(2);
};
