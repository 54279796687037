import PropTypes from 'prop-types';
import cx from 'classnames';

import sty from './button.module.scss';

function ButtonGroup(props) {
  const {
    type,
    handleClickNext,
    handleClickBack,
    colorType,
    label,
    className = '',
    disableNext,
    disableBack,
    labelBack,
    btnType,
    btnCustomStyle = {},
  } = props;

  function classBtn(cond) {
    return `${
      // eslint-disable-next-line no-nested-ternary
      cond
        ? cx(sty['btn-secondary'], sty['btn-disabled'])
        : colorType === 1
        ? sty['btn-success']
        : sty['btn-success-blue']
    } `;
  }
  switch (type) {
    case 1:
      return (
        <button
          type={btnType || 'button'}
          className={cx(sty.btn, className, classBtn(disableNext))}
          onClick={handleClickNext}
          data-toggle="tooltip"
          data-placement="top"
          style={btnCustomStyle}
        >
          {label}
        </button>
      );
    case 2:
      return (
        <div className={className}>
          <button
            type="button"
            className={cx(sty.btn, sty['m-right-2'], classBtn(disableBack))}
            onClick={handleClickBack}
            style={btnCustomStyle}
          >
            {labelBack}
          </button>
          <button
            type="button"
            className={cx(sty.btn, classBtn(disableNext))}
            onClick={handleClickNext}
            style={btnCustomStyle}
          >
            {label}
          </button>
        </div>
      );
  }
}

ButtonGroup.propTypes = {
  type: PropTypes.number,
  handleClickNext: PropTypes.func,
  handleClickBack: PropTypes.func,
  colorType: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  disableNext: PropTypes.bool,
  disableBack: PropTypes.bool,
  labelBack: PropTypes.string,
  btnType: PropTypes.string,
  btnCustomStyle: PropTypes.any,
};

export default ButtonGroup;
