import cx from 'classnames';
import PropTypes from 'prop-types';

import sty from './loader.module.scss';

function Loader({ showNoBg = false }) {
  return (
    <div
      className={cx(sty['loader-container'], sty.backdrop, { [sty['overlay-colored']]: showNoBg })}
    >
      {showNoBg && <div className={sty.message}>Payment Loyalty</div>}
      <div className={cx(sty.item, sty['item--1'])} />
      <div className={cx(sty.item, sty['item--2'])} />
      <div className={cx(sty.item, sty['item--3'])} />
      <div className={cx(sty.item, sty['item--4'])} />
      <div className={cx(sty.item, sty['item--5'])} />
    </div>
  );
}

Loader.propTypes = {
  showNoBg: PropTypes.bool,
};

export default Loader;
