/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import router from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import App from 'next/app';
import Cookie from 'cookie';

import { SettingWrapper } from '@context/SettingContent';
import { UserWrapper } from '@context/UserContent';
import { MessageWrapper } from '@context/MessageContext';
import { CampaignWrapper } from '@context/CampaignContext';
import { Layout } from '@components';
import URL from '@helpers/url-constant';
import { saveState } from '@helpers/common';
import { unAuthorizedPages } from '@helpers/page-constant';
import { getMetaData } from '@apis/Meta';

import '@styles/global.scss';
import { loginUser } from '@apis/Auth';
import { ThemeProvider } from '@context/ThemeProvider';

function redirectToLogin(ctx) {
  if (!unAuthorizedPages.includes(ctx.pathname)) {
    loginUser();
    ctx.res.writeHead(301, {
      Location: URL.LOGIN,
    });
    ctx.res.end();
  }
}

// refresh token still exist
function redirectToHome(ctx) {
  if (unAuthorizedPages.includes(ctx.pathname)) {
    ctx.res.writeHead(301, {
      Location: URL.DASHBOARD,
    });
    ctx.res.end();
  }
}

const RouteProgressBar = dynamic(
  () => {
    return import('../components/RouteProgressBar');
  },
  { ssr: false },
);

const MyApp = ({ initialData, Component, pageProps }) => {
  const [clientName] = useState(initialData?.user?.clientName ?? '');
  useEffect(() => {
    const {
      pathname,
      query: { id },
    } = router;
    if (pathname === `${URL.AUTH_CARD}/[id]`) {
      saveState('paymentIntentId', id);
    }
  }, []);

  return (
    <ThemeProvider clientName={clientName}>
      <MessageWrapper>
        <UserWrapper userData={initialData.user}>
          <SettingWrapper metaObj={initialData.data}>
            <CampaignWrapper>
              <Layout accessToken={initialData?.user?.accessToken}>
                <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Head>
                <RouteProgressBar />
                <Component {...pageProps} />
              </Layout>
            </CampaignWrapper>
          </SettingWrapper>
        </UserWrapper>
      </MessageWrapper>
    </ThemeProvider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  initialData: PropTypes.object,
};

export default MyApp;

MyApp.getInitialProps = async appContext => {
  let initialData = {};
  const { clientName } = appContext.ctx.query;
  if (appContext.ctx.req) {
    initialData = await getMetaData();
    const request = appContext.ctx.req;
    if (request) {
      const cookie = Cookie.parse(request.headers.cookie || '');

      try {
        if (cookie.token) {
          const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/token`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': cookie.token,
            },
            redirect: 'follow',
            referrer: 'no-referrer',
          });
          const result = await res.text();

          console.log('************');
          console.log('API RESPONSE', result);
          console.log('************');
          const tokenData = JSON.parse(result);

          if (tokenData.status) {
            redirectToHome(appContext.ctx);
            initialData.user = {
              accessToken: tokenData.data.accessToken,
              refreshToken: cookie.token,
              merchantId: tokenData.data.id,
              email: tokenData.data.email,
              id: tokenData.data.id,
              clientName: cookie.clientName ?? clientName,
            };
          } else {
            initialData.user = {
              clientName: clientName ?? cookie.clientName,
            };
            redirectToLogin(appContext.ctx);
          }
        } else {
          initialData.user = {
            clientName: clientName ?? cookie.clientName,
          };
          redirectToLogin(appContext.ctx);
        }
      } catch (e) {
        console.log('******************************************************');
        console.log('-----------------------ERROR--------------------------');
        console.log('Error', e);
        console.log('******************************************************');
      }
    }
  }

  // Call the page's `getInitialProps` and fill `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, initialData };
};
