import { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { useUserContext } from '@context/UserContent';
import URL from '@helpers/url-constant';
import { loadState, removeState, saveState } from '@helpers/common';
import { getCampaign } from '@apis/Campaign';

const CampaignContext = createContext();

export function CampaignWrapper({ children }) {
  const router = useRouter();

  const { user } = useUserContext();

  const [campaignData, handleCampaignData] = useState({});
  const [preview, setPreview] = useState(false);
  const prevRoute = useRef(null);

  // get and save campaign when the application loads with the campaign pages
  useEffect(() => {
    async function fetchCampaignData() {
      const result = await getCampaign(user.accessToken, router.query.id || loadState('campId'));
      if (result.status && result.data.campaignId) {
        handleCampaignData(result.data);
      } else {
        resetCampaignData();
      }
    }

    if (user.accessToken && router.pathname.includes('campaign/') && !prevRoute.current) {
      prevRoute.current = router.pathname.includes('campaign/');
      if (router.query.id || loadState('campId')) {
        fetchCampaignData();
      } else {
        resetCampaignData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accessToken, router.pathname]);

  // store the campaign id in session storage whenever campaign routes are accessed
  useEffect(() => {
    if (campaignData.id && campaignData.status !== 7) {
      saveState('campId', campaignData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData.id]);

  useEffect(() => {
    if (!router.pathname.includes('campaign/') && prevRoute.current) {
      prevRoute.current = false;
      removeState();
      handleCampaignData({});
    }
  }, [router.pathname]);

  function resetCampaignData() {
    removeState();
    handleCampaignData({});
    router.push(URL.CREATE_CAMPAIGN);
  }

  const sharedState = {
    campaignData,
    handleCampaignData,

    preview,
    setPreview,
  };

  return <CampaignContext.Provider value={sharedState}>{children}</CampaignContext.Provider>;
}

export function useCampaignContext() {
  return useContext(CampaignContext);
}

CampaignWrapper.propTypes = {
  children: PropTypes.any,
};
