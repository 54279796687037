import PropTypes from 'prop-types';
import cx from 'classnames';

import sty from './icon.module.scss';

function Icon({ icon, color = 'black', size }) {
  return (
    <span
      className={cx(sty.icon, sty[color], sty[size])}
      style={{
        WebkitMaskImage: `url(${icon})`,
        maskImage: `url(${icon})`,
      }}
    />
  );
}

Icon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
