import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MessageContext = createContext();

export function MessageWrapper({ children }) {
  const [error, setErrors] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    // clear error state after 1min.
    if (error) {
      const time = setTimeout(() => {
        clearTheErrors();
        clearTimeout(time);
      }, 60000);
    }
  }, [error]);

  useEffect(() => {
    // clear success state after 1min.
    if (success) {
      const time = setTimeout(() => {
        clearTheSuccess();
        clearTimeout(time);
      }, 6000);
    }
  }, [success]);

  // Error
  function contextErrorHandler(errorMsg) {
    if (errorMsg) {
      setErrors(errorMsg);
    }
  }

  function clearTheErrors() {
    setErrors('');
  }

  // Succes
  function contextSuccessHandler(successMsg) {
    if (successMsg) {
      setSuccess(successMsg);
    }
  }

  function clearTheSuccess() {
    setSuccess('');
  }

  const sharedState = {
    error,
    contextErrorHandler,
    clearTheErrors,

    success,
    contextSuccessHandler,
    clearTheSuccess,
  };

  return <MessageContext.Provider value={sharedState}>{children}</MessageContext.Provider>;
}

MessageWrapper.propTypes = {
  children: PropTypes.any,
};

export function useMessageContext() {
  return useContext(MessageContext);
}
