import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useUserContext } from '@context/UserContent';
import { getSetting } from '@apis/Meta';
import { useTheme } from './ThemeProvider';

const SettingContext = createContext();

export function SettingWrapper({ children, metaObj }) {
  const { clientConfig } = useTheme();
  const { user } = useUserContext();

  const [setting, handleSetting] = useState({
    countryId: 1,
    countryDateFormat: 'MM / dd / yyyy',
    currencySymbol: '$',
    providerId: 1,
    deviceId: 2,
  });
  const [metaData, handleMetaData] = useState({
    business_type: [],
    country: [],
    device_type: [],
    service_provider: [],
  });

  useEffect(() => {
    async function callSettingApi() {
      const res = await getSetting(user.accessToken);
      if (res.status) {
        const countryInfo = metaData.country.find(country => country.id === res.data.country);
        handleSetting({
          countryId: res.data.country,
          currencySymbol: countryInfo.currency_symbol,
          countryDateFormat: countryInfo.date_format,
          providerId: res.data.provider,
          deviceId: res.data.deviceId || clientConfig.deviceId,
        });
      }
    }

    if (user.accessToken && metaData.country.length) {
      callSettingApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData, user.accessToken]);

  useEffect(() => {
    if (metaObj?.country?.length) {
      handleMetaData(metaObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaObj]);

  function updateDevice(id) {
    handleSetting(state => ({ ...state, deviceId: id }));
  }

  const sharedState = {
    setting,
    handleSetting,
    metaData,
    updateDevice,
  };

  return <SettingContext.Provider value={sharedState}>{children}</SettingContext.Provider>;
}

export function useSettingContext() {
  return useContext(SettingContext);
}

SettingWrapper.propTypes = {
  children: PropTypes.any,
  metaObj: PropTypes.object,
};
