import REQUEST from '@helpers/http.service';

export async function getMetaData() {
  try {
    const res = await REQUEST({
      method: 'GET',
      url: `/get-meta-data`,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function getSetting(accessToken) {
  try {
    const res = await REQUEST({
      method: 'GET',
      url: `/setting`,
      accessToken,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function postSettings(accessToken, data) {
  try {
    const res = await REQUEST({
      method: 'PUT',
      url: '/setting',
      accessToken,
      data,
    });
    return res.data;
  } catch (error) {
    return error;
  }
}
