// helper function to change the color to darker or lighter (colorCode,50 or -50)
/* eslint-disable no-bitwise */
const newShade = (color, magnitude) => {
  const hexColor = color.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r = Math.min(255, Math.max(0, r));
    let g = ((decimalColor >> 8) & 0x00ff) + magnitude;
    g = Math.min(255, Math.max(0, g));
    let b = (decimalColor & 0x0000ff) + magnitude;
    b = Math.min(255, Math.max(0, b));
    const newColor = (r << 16) | (g << 8) | b;
    return `#${newColor.toString(16).padStart(6, '0')}`;
  }
  return hexColor;
};

// Define client styles with specific properties
export const themeProperties = {
  default: {
    deviceId: 2,
    primary: '#fe6f61',
    background: '#e0e5e7',
    onBackground: '#475260',
    primaryButtonColor: '#fe6f61',
    onPrimaryButton: '#ffffff',
    linkColor: '#fe6f61',
    logoBg: '#ffffff',
    logo: '/images/new-brand-logo.svg',
    welcomeImg: '/images/pl-default-welcome.png',
    welcomeImgWebp: '/images/pl-default-welcome.webp',
  },
  lopay: {
    deviceId: 5,
    primary: '#727eb9',
    background: '#e0e5e7',
    onBackground: '#475260',
    primaryButtonColor: '#727eb9',
    onPrimaryButton: '#ffffff',
    linkColor: '#727eb9',
    logoBg: '#ffffff',
    logo: '/images/psp/lopay.png',
    welcomeImg: '/images/lopay-welcome.png',
    welcomeImgWebp: '/images/lopay-welcome.webp',
  },
  handepay: {
    deviceId: 4,
    primary: '#0171bb',
    background: '#0171bb',
    onBackground: '#ffffff',
    primaryButtonColor: newShade('#0171bb', -30),
    onPrimaryButton: '#ffffff',
    linkColor: '#e5e5e5',
    logoBg: '#0171bb',
    logo: '/images/handepay-logo.png',
    welcomeImg: '/images/castles-welcome.png',
    welcomeImgWebp: '/images/castles-welcome.webp',
  },
  // Add more clients as needed
};

// Define the function to get client style based on client name
export default function getClientConfigByName(clientName) {
  // Check if clientName is valid and exists in themeProperties
  if (clientName && themeProperties[clientName]) {
    return themeProperties[clientName];
  }
  // Return default style if clientName is invalid or does not exist in clientStyles
  return themeProperties.default;
}
