const URL = {
  LOGIN: '/',
  REGISTER: '/register',
  DYNAMIC_CLIENT_LOGIN: '/[clientName]',
  DYNAMIC_CLIENT_SIGNUP_URL: '/[clientName]/register',

  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/[token]',
  CREATE_PASSWORD: '/account/activate/[token]',

  DASHBOARD: '/dashboard',
  BUSINESS_INSIGHT: '/business-insight',
  MANAGE_CAMPAIGNS: '/campaigns',

  CREATE_CAMPAIGN: '/campaign/create',
  UPDATE_CAMPAIGN: '/campaign/update',
  CAMPAIGN: '/campaign',
  DESIGN_CAMPAIGN: '/campaign/design',
  CAMPAIGN_SETUP: '/campaign/conditions',
  CAMPAIGN_REVIEW: '/campaign/review',
  CAMPAIGN_LAUNCH: '/campaign/launch',
  BUNDLE_GENERATOR: '/campaign/generate-bundle',

  TERMS_AND_COND: '/terms-and-conditions',

  SETTINGS: '/settings',
  USERS: '/settings/users',
  USER_EDIT: '/settings/user/:id',
  USER_CREATE: '/settings/users/create',
  BILLING: '/settings/billing' /*  card */,
  ADD_CARD: '/settings/billing/add-card',
  AUTH_CARD: '/settings/billing/authenticate-card',
  INVOICE: '/settings/invoice',
  DEVICE: '/settings/device',
  PAYMENT_PROVIDER: '/settings/payment-provider',
  MERCHANT_ADDRESS: '/settings/merchant-address',

  INVALID_LINK: '/invalid-link',
};

export default URL;
