import propTypes from 'prop-types';
import { BarChart } from '@components';
import styles from './ParticipationChart.module.scss';

// Month names mapping based on month number
const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const ParticipationChart = ({ data }) => {
  // Extract arrays for months, optedIn, and optedOut
  const monthLabels = data.map(entry => monthNames[entry.month - 1]);
  const optedInStats = data.map(entry => parseInt(entry.optedIn, 10));
  const optedOutStats = data.map(entry => parseInt(entry.optedOut, 10));

  const chartData = {
    labels: monthLabels,
    backgroundColors: [['#009de1'], ['#5C6BC0']],
    barTitle: ['Opted-In', 'Opted-Out'],
    data: [optedInStats, optedOutStats],
  };

  return (
    <div className={styles.participationChart}>
      <BarChart
        title="Campaign Participation Graph"
        chartData={chartData}
        legendConfig={{
          display: true,
          position: 'top',
          padding: 5,
          labels: {
            usePointStyle: false, // Use circles for legend markers
            pointStyle: 'circle', // Rounded point style
            boxWidth: 10, // Control the width of the circles
            boxHeight: 10, // Control the height of the circles (if needed)
            padding: 10, // Padding between the items
          },
        }}
      />
    </div>
  );
};

ParticipationChart.propTypes = {
  data: propTypes.any,
};

export default ParticipationChart;
