/* eslint-disable react/prop-types */

function DateCustomInput(props) {
  const { placeholderText, value, onClick, className = '' } = props;
  return (
    <div
      className="DayPickerInput-custom"
      onClick={onClick}
      onKeyDown={event => {
        if (event.keycode === 13) {
          onClick();
        }
      }}
      role="presentation"
    >
      <input
        type="text"
        placeholder={placeholderText}
        className={className}
        value={value}
        readOnly
      />
      <button type="button">
        <img src="/images/calendar-icon.svg" alt="calendar-icon" />
      </button>
    </div>
  );
}

export default DateCustomInput;
