/* eslint-disable import/prefer-default-export */
import URL from '@helpers/url-constant';

export const unAuthorizedPages = [
  URL.LOGIN,
  URL.REGISTER,
  URL.RESET_PASSWORD,
  URL.FORGOT_PASSWORD,
  URL.CREATE_PASSWORD,
  URL.DYNAMIC_CLIENT_LOGIN,
  URL.DYNAMIC_CLIENT_SIGNUP_URL,
  '/404',
];
