import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getClientStyle from '../helpers/theme-values-provider';

// Create a context for the theme
const ThemeContext = createContext();

/**
 * ThemeProvider component
 * This component provides theme-related context to its children components.
 *
 * @param {Object} props - The properties object.
 * @param {PropTypes.any} props.children - The children components that will consume the context.
 * @param {string} [props.clientName=''] - The initial client name for theme determination.
 */
export const ThemeProvider = ({ children, clientName = '' }) => {
  // State to hold the current client name
  const [client, setClient] = useState(clientName);
  // State to hold the current client theme
  const [clientConfig, setTheme] = useState(getClientStyle(client.replace(/-/g, '').toLowerCase()));

  /**
   * Function to change the theme based on the new client name
   *
   * @param {string} newClientName - The new client name to determine the theme.
   */
  const changeTheme = newClientName => {
    const formattedName = newClientName.replace(/-/g, '').toLowerCase();
    setClient(formattedName);
    setTheme(getClientStyle(formattedName));
  };

  // Effect to update the theme when the clientName prop changes
  useEffect(() => {
    changeTheme(clientName);
  }, [clientName]);

  return (
    <ThemeContext.Provider value={{ client, clientConfig, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * Custom hook to use the theme context
 *
 * @returns {Object} The theme context value, including the client theme and the changeTheme function.
 */
export const useTheme = () => useContext(ThemeContext);

ThemeProvider.propTypes = {
  children: PropTypes.any, // The children components to be rendered inside the provider
  clientName: PropTypes.string, // The initial client name (optional)
};
