import { useEffect, useState, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { useUserContext } from '@context/UserContent';
import { Sidebar, Loader } from '@components';
import URL from '@helpers/url-constant';
import { loadState } from '@helpers/common';
import { unAuthorizedPages } from '@helpers/page-constant';
import { logoutUser } from '@apis/Auth';

import style from './layout.module.scss';

function Layout({ children, accessToken }) {
  const router = useRouter();

  const { user, handleUser } = useUserContext();

  const [clearUser, handleClearUser] = useState(false);
  const [loading, handleLoading] = useState(false);

  useEffect(() => {
    if (router.pathname === URL.LOGIN && user.accessToken) {
      const paymentIntentId = loadState('paymentIntentId');
      const path = paymentIntentId ? `${URL.AUTH_CARD}/${paymentIntentId}` : URL.DASHBOARD;
      router.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accessToken]);

  useEffect(() => {
    if (!user.refreshToken && clearUser) {
      handleLoading(false);
      window.location.replace(URL.LOGIN);
    }
  }, [user, clearUser]);

  async function handleLogout() {
    handleLoading(true);
    const { status } = await logoutUser();
    if (status) {
      handleUser({});
      handleClearUser(true);
    }
  }

  const authenticatedRoute = !unAuthorizedPages.includes(router.pathname);

  return (
    <div className={style['site-wrapper']}>
      {/* loader */}
      {loading && <Loader />}

      {/* Sidebar code */}
      {authenticatedRoute && <Sidebar handleLogout={handleLogout} />}

      {/* Context code */}
      <main className={style['content-wrapper']}>
        {authenticatedRoute
          ? Children.map(children, child =>
              cloneElement(child, { accessToken: user.accessToken || accessToken }),
            )
          : children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  accessToken: PropTypes.string,
};

export default Layout;
