import Router from 'next/router';
import NProgress from 'nprogress';

let timer;
let state;
let activeRequests = 0;
const delay = 250;

NProgress.configure({
  trickle: false,
  template:
    "<div class='bar' role='bar'><div class='peg'></div></div><div class='spinner' role='spinner'><div class='item item--1'></div><div class='item item--2'></div><div class='item item--3'></div><div class='item item--4'></div><div class='item item--5'></div></div>",
});

function load() {
  if (state === 'loading') {
    return;
  }

  state = 'loading';

  timer = setTimeout(() => {
    NProgress.start();
  }, delay); // only show progress bar if it takes longer than the delay
}

function stop() {
  if (activeRequests > 0) {
    return;
  }

  state = 'stop';

  clearTimeout(timer);
  NProgress.done();
}

Router.events.on('routeChangeStart', load);
Router.events.on('routeChangeComplete', stop);
Router.events.on('routeChangeError', stop);

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  const originalFetch = isBrowser && window.fetch;

  window.fetch = async (...args) => {
    if (activeRequests === 0) {
      load();
    }

    activeRequests++;

    try {
      const response = await originalFetch(...args);
      return response;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      activeRequests -= 1;
      if (activeRequests === 0) {
        stop();
      }
    }
  };
}

export default function RouteProgressBar() {
  return null;
}
