import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import sty from './input.module.scss';

import WARNING_ICON from '../../../public/images/warning-red.svg';
import SHOW_PASSWORD from '../../../public/images/show-password.svg';
import HIDE_PASSWORD from '../../../public/images/hide-password.svg';

function Input({
  name,
  title,
  placeholder,
  initialValue,
  type = 'text',
  min,
  max,
  prefix,
  suffix,
  validations = [],
  disabled,
  pristine = true,
  onChange,
  onError,
  upperCase = false,
  customStyle = {},
}) {
  const [value, setValue] = useState('');
  const [errors, setErrors] = useState([]);
  const [showPassword, handlePassword] = useState(false);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (onError) {
      onError({ name, value: errors.length ? errors[0] : false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!pristine) {
      handleValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pristine]);

  const handleChange = value => {
    const tempValue = type === 'email' ? String(value).toLowerCase() : value;

    setValue(tempValue);

    if (onChange) {
      onChange(upperCase ? tempValue.toUpperCase() : tempValue);
    }
  };

  const handleValidation = () => {
    if (validations.length) {
      const tempErrors = [];
      for (let index = 0; index < validations.length; index++) {
        const element = validations[index];
        const pattern = new RegExp(element.validation);

        if (!pattern.test(value) && !element.notRequired) {
          tempErrors.push(element.message);
        } else if (element.notRequired && value.length && !pattern.test(value)) {
          tempErrors.push(element.message);
        }
      }
      setErrors(tempErrors);
    }
  };

  return (
    <div className={sty.input}>
      {title && <h5 className={sty.title}>{title}</h5>}

      <div className={sty['input-wrapper']} data-prefix={prefix} data-suffix={suffix}>
        <input
          className={cx({ [sty.upperCase]: upperCase })}
          name={name}
          type={showPassword ? 'text' : type}
          placeholder={placeholder}
          value={value}
          onChange={e => handleChange(e.target.value)}
          onBlur={handleValidation}
          disabled={disabled}
          minLength={min}
          maxLength={max}
          style={customStyle}
        />
      </div>
      {type === 'password' && (
        <button
          type="button"
          className={sty['visibility-btn']}
          onClick={() => handlePassword(!showPassword)}
        >
          <img src={showPassword ? SHOW_PASSWORD : HIDE_PASSWORD} alt="visibility" />
        </button>
      )}

      {errors.length ? (
        <div className="errors-wrapper">
          <img src={WARNING_ICON} alt="warning-icon" />
          <p className="error">{errors[0]}</p>
        </div>
      ) : null}
    </div>
  );
}

export default Input;

Input.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validations: PropTypes.array,
  disabled: PropTypes.bool,
  pristine: PropTypes.bool,
  upperCase: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  customStyle: PropTypes.any,
};
