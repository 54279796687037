/* eslint-disable no-console */
import axios from 'axios';

import { GLOBAL_TOKEN } from '@context/UserContent';
import { logoutUser } from '@apis/Auth';
import URL from '@helpers/url-constant';

const REQUEST = async config => {
  let originalReq;

  try {
    const HTTP = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    });

    HTTP.interceptors.request.use(request => {
      if (config.accessToken && !request.headers['x-auth-token']) {
        request.headers = {
          'x-auth-token': config.accessToken,
        };
      }
      request.url = request.baseURL + config.url;
      request.data = config.data;
      request.method = config.method;
      return request;
    });

    HTTP.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201) {
          if (originalReq) {
            window.location.reload(false);
          }
          return response;
        }
      },
      async error => {
        if (
          (error.response?.status === 404 ||
            error.response?.status === 413 ||
            error.response?.status === 400 ||
            error.response?.status === 500) &&
          Object.prototype.hasOwnProperty.call(error.response.data, 'status')
        ) {
          return error.response;
        }
        if (error.response?.status === 401) {
          if (!originalReq) {
            console.log('Session has been expired, fetching the new access token!!');

            const token = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/token`, {
              method: 'GET',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': GLOBAL_TOKEN.token,
              },
              redirect: 'follow',
              referrer: 'no-referrer',
            });
            const res = await token.text();
            const tokenData = JSON.parse(res);

            if (tokenData.status) {
              originalReq = error.config;

              // eslint-disable-next-line no-underscore-dangle
              originalReq._retry = true;

              originalReq.headers['x-auth-token'] = tokenData.data.accessToken;

              return HTTP(originalReq);
            }
            await logoutUser();
            window.location.replace(URL.LOGIN);
          }
          if (originalReq) {
            await logoutUser();
            window.location.replace(URL.LOGIN);
          }
        }

        return {
          data: {
            status: false,
            message: 'Something went wrong',
            data: {},
          },
        };
      },
    );
    return HTTP();
  } catch (e) {
    console.log(e);
    console.log('Something went wrong!!');
  }
};

export default REQUEST;
