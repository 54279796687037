const defaultChartColors = [
  '#009de1', // Blue
  '#5C6BC0', // Indigo
  '#FFB74D', // Amber
  '#4DB6AC', // Teal
  '#FF7043', // Deep Orange
  '#AB47BC', // Purple
  '#FF8A65', // Light Orange
  '#F06292', // Pink
  '#64B5F6', // Light Blue
  '#4CAF50', // Green
  '#FFD54F', // Yellow
  '#9E9D24', // Lime
];

export default defaultChartColors; // Export the array for reuse
