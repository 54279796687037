import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const UserContext = createContext();

export const GLOBAL_TOKEN = {
  token: '',
};

export const TEMP_USER = {};
export function UserWrapper({ children, userData }) {
  const [user, handleUser] = useState({});

  useEffect(() => {
    async function saveToken(data) {
      await axios({
        method: 'POST',
        url: '/api/login',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: data.refreshToken,
          clientName: data.clientName,
        },
      });
    }

    if (user.refreshToken) {
      GLOBAL_TOKEN.token = user.refreshToken;
      saveToken(user); // Change to refreshToken
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.refreshToken]);

  useEffect(() => {
    if (userData?.accessToken) {
      handleUser(userData);
    }
  }, [userData]);

  const sharedState = { user, handleUser };

  return <UserContext.Provider value={sharedState}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  return useContext(UserContext);
}

UserWrapper.propTypes = {
  children: PropTypes.any,
  userData: PropTypes.object,
};
