import PropTypes from 'prop-types';
import { Card } from '@components';
import { Pie } from 'react-chartjs-2';
import styles from './table.module.scss';

const Table = ({ title, subTitle, headers, data }) => {
  const formatPercentage = percentage => {
    if (percentage === 0) return '0.00%';
    if (percentage % 1 === 0) return `${percentage}%`; // Return as integer if it's a whole number
    return `${percentage.toFixed(2)}%`; // Return with 2 decimal places if it's not a whole number
  };
  const renderPieChart = percentage => {
    const pieData = {
      labels: ['Filled', 'Remaining'],
      datasets: [
        {
          data: [percentage, 100 - percentage],
          backgroundColor: ['#57C1F5', '#e6e6e6'],
          borderWidth: 0.5,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: false,
        },
      },
      animation: {
        duration: 2000, // Set animation duration to 1000 milliseconds (1 second)
      },
    };

    return <Pie data={pieData} options={options} />;
  };
  return (
    <Card>
      <div className={styles.container}>
        {title ? (
          <div className={styles.chartHeader}>
            <h3 className={styles.chartTitle}>
              {title}
              {subTitle ? <span className={styles.chartSubTitle}>{` (${subTitle})`}</span> : null}
            </h3>
          </div>
        ) : null}
        <table className={styles.rewardTable}>
          <thead>
            <tr>
              {headers.map((column, index) => (
                <th key={index}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.isPercentage ? (
                      <div className={styles.performanceWrapper}>
                        <span>{formatPercentage(item[column.key])}</span>
                        <div className={styles.pieChart}>{renderPieChart(item[column.key])}</div>
                      </div>
                    ) : (
                      item[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  headers: PropTypes.array,
  data: PropTypes.array,
};

export default Table;
